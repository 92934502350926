.menu--tertiary {
  position: relative;
  font-size: px-to-rem(20);
  flex-direction: column;
  gap: 1em;


  &:hover {
    .menu__link {
        opacity: 0.5;
    }
}

  .menu__item {
      height: initial;
      padding: unset;
      position: unset;
      color: $white;

      &:hover {
          .menu__link {
              opacity: 1;
          }

          .menu__item-counter {
              opacity: 1;
          }
      }
  }

  .submenu {
      background-color: unset;
  }

}

;@import "sass-embedded-legacy-load-done:271";