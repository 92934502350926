// Default swiper
.swiper {
    &__slide {
        @extend .swiper-slide;
    }

    &__nav {
        display: flex;
        align-items: center;
        gap: spacer(16);
        position: relative;
        margin-top: spacer(8);
    }

    &__progress {
        position: relative;
        border-radius: spacer(4);
        overflow: hidden;
        height: 1px !important;
        background-color: rgba($color-base, 0.1);
        width: 100% !important;

        .swiper-pagination-progressbar-fill {
            background-color: $color-base;
            border-radius: inherit;
        }

        &.swiper-pagination-lock {
            display: block;
        }
    }

    &__button {
        z-index: 9;
        background-color: $white;
        transition: all 0.3s;
        border-radius: 100%;
        color: $secondary;
        height: spacer(12);
        width: spacer(12);
        padding: 0;
        justify-content: center;
        align-items: center;
        display: flex;

        @include media-breakpoint-up(lg) {
            height: spacer(16);
            width: spacer(16);
        }

        &:hover {
            background-color: $primary;
            color: $white;
        }

        &--disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &--prev {
            .icon {
                transform: scaleX(-1);
            }
        }

        &s {
            display: flex;
            align-items: center;
            gap: spacer(2);
        }
    }

    .slide__text {
        position: absolute;
        inset: 0;
        left: 5vw;
        right: 5vw;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    @each $name, $breakpoint in $grid-breakpoints {
        &.swiper--destroy-#{$name} {
            @include media-breakpoint-up($name) {
                margin: 0 -20px;

                .swiper__slide {
                    margin: 0 !important;
                    padding: 20px;
                    width: 25% !important;

                    &.swiper-slide-duplicate {
                        display: none;
                    }
                }

                .swiper-wrapper {
                    transform: none !important;
                    flex-wrap: wrap;
                    height: unset !important;
                }
            }
        }
    }

    &--image {
        overflow: visible !important;
        
        @include media-breakpoint-up(lg) {
            overflow: hidden !important;
        }

        .swiper__slide {
            height: 300px;

            @include media-breakpoint-up(lg) {
                height: 500px;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: $border-radius-lg;
            }
        }

        .swiper__nav {
            position: absolute;
            bottom: spacer(6);
            right: spacer(15);
        }
    }

    &--image_text {
        .slide__text {
            position: relative;
            left: 0;
            right: 0;
            padding: 5vw;

            @include media-breakpoint-up(lg) {
                position: absolute;
                left: 5vw;
                right: 5vw;
                padding: 0;
            }
        }

        .swiper__slide {
            .img-fluid {
                @include media-breakpoint-down(lg) {
                    position: absolute;
                    inset: 0;
                    height: 100%;
                    object-fit: cover;
                    min-height: 70vh;
                }
            }
        }
    }

    &--text {
        max-width: 100%;

        .swiper-wrapper {
            max-width: 100%;
        }

        .swiper__slide {
            opacity: 0 !important;
            max-width: 100%;
            
            * {
                color: $white;
                font-size: px-to-rem(40);
                font-weight: $font-weight-semibold;
            }
            
            &.swiper-slide-active {
                opacity: 1 !important;
            }
        }
    }

    &--logo {
        overflow: visible !important;
        
        .swiper-wrapper {
            transition-timing-function : linear !important;
        }
    }
}

// Used for cards/posts carousel
.swiper-container {
    min-width: 0;
    min-height: 0;
}


.swiper__text, .tagline {
    line-height: 1.15;
}

;@import "sass-embedded-legacy-load-done:255";