.card-text {
  .card__body {
    padding: 0;
  }

  .card__footer {
    padding:0;
  }

  .card__title {
    font-size: px-to-rem(36);
  }
}
;@import "sass-embedded-legacy-load-done:250";