
.projects {
    .card--project {
        height: fit-content;
        min-height: unset;
        
        .card__header {
            height: auto;
        }
    }
}

.pane--swiper_projects {
    overflow: hidden;

    .swiper__slide {
        height: 100%;
    }

    .swiper--projects {
        overflow: visible;
    }
}


.swiper--projects-vertical {
    max-height: 100vh;
    overflow: visible !important; 

    .swiper__slide {
        height: auto !important;
        
        .card--project {
            margin: 0 auto;
            width: 95%;
            transition: width $global_duration;
        }

        &.swiper-slide-active {
            .card--project {
                width: 100%;
            }
        }

        &.swiper-slide-next {
            z-index: 3 !important;
        }
    }
}


;@import "sass-embedded-legacy-load-done:263";