.menu--primary {
    justify-content: center;
    padding: spacer(8);

    @include media-breakpoint-up(lg) {
        padding: unset;
    }

    // Menu item level 1
    & > .menu__item {
        display: flex;
        align-items: stretch;
        transition: background-color $global-duration ease-in;

        &:hover {
            @include media-breakpoint-up(lg) {
                // background-color: $primary;
            }
        }
    }

    .menu__link {
        display: flex;
        align-items: center;
        height: 100%;

        @include media-breakpoint-up(lg) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:279";