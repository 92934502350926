@use 'sass:color';

.facetwp-facet {
    color: $secondary;
    
    .checked {
        font-weight: $font-weight-bold;
    }
}

.facetwp-counter {
    display: none;
}

.facetwp-checkbox {
    background-image: url('../../img/icons/checkbox.svg') !important;

    &.checked {
        background-image: url('../../img/icons/checkbox-on.svg') !important;
    }
}

.filters {
    position: sticky;
    left: 50%;
    bottom: 0;
    padding: spacer(8) spacer(10) spacer(3) spacer(10);
    transform: translateX(-50%);
    width: max-content;
    background: transparent;
    transition: all 300ms ease-in-out;
    border-radius: $border-radius-lg $border-radius-lg 0 0;
    box-shadow: unset;

    .filters__close {
        position: absolute;
        top: spacer(3);
        right: spacer(3);
        padding: spacer(1);
        z-index: 1;
        background: $quaternary;
        visibility: hidden;
        border-radius: $border-radius-pill;
        transition: all 300ms ease-in-out;
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transform: translateY(100%);
        cursor: pointer;

        .material-icons {
            font-size: px-to-rem(16);
            color: $secondary;
        }
    }
    
    .filters__button {
        cursor: pointer;

        @extend .btn;
        @extend .btn--secondary;
    }

    .filters__content {
        opacity: 0;
        visibility: hidden;
        transform: translateY(100%);
        background-color: $white;
        transition: all 300ms ease-in-out;

        .facet-wrap {
            margin-bottom: spacer(8);
        }

        .facet-label {
            font-size: px-to-rem(20);
            padding-bottom: spacer(3);
            border-bottom: 1px solid rgba($color-base, 0.1);
        }

        .facetwp-facet {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &--is-open {
        box-shadow: rgba($black, .02) 0 0 4px 3px;
        background: $white;

        .filters__close {
            visibility: visible;
            opacity: 1;
            transform: translateY(0%);
            
            &:hover {
                background: color.adjust($quaternary, $lightness: -10%);
            }
        }

        .filters__content {
            display: block;
            opacity: 1;
            visibility: visible;
            transform: translateY(0%);
        }
    }
}

;@import "sass-embedded-legacy-load-done:243";