img {
    @extend .img-fluid;

    &.aligncenter {
        display: block;
        margin: 0 auto;
    }

    &.alignright {
        display: block;
        margin-left: auto;
    }

    &.alignleft {
        display: block;
        margin-right: auto; 
    }

    &.img--border-radius {
        border-radius: $border-radius-lg;
    }
}

;@import "sass-embedded-legacy-load-done:238";