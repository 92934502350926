.editor--animated {
  & > * {
    color: $color-base;
    display: inline;
    -webkit-text-fill-color: rgba($color-base, 0.5);
    background-clip: text;
    background-repeat: no-repeat;
    background-image: linear-gradient($color-base, $color-base);
    background-size: 0% 100%;
  }
}
;@import "sass-embedded-legacy-load-done:304";