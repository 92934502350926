// Card component

.card {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: $white;
    box-shadow: $box-shadow;
    min-height: 100%;
    transition: all 350ms ease-in;
    width: 100%;
    border: 1px solid rgba($color-base, 0.1);
    border-radius: $border-radius;
    overflow: hidden;
    margin-top: 0;
    padding: 0;
    min-width: unset;
    max-width: unset;

    &:hover {
        box-shadow: $box-shadow-hover;
    }
}

.card__icon {
    padding: spacer(2);
    display: flex;
    aspect-ratio: 1/1;
    border: 1px solid rgba(39, 38, 53, 12%);
    border-radius: 50rem;
    transition: all 150ms ease-in;

    &--hover {
        background-color: $primary;
        color: $white;
    }
}


.card__progress-container {
    display: flex;
    align-items: center;
    gap: spacer(5);
}

.card__progress {
    height: 1px;
    flex-shrink: 0;
    width: 100%;
    background-color: #e8e8e8;
    position: relative;

    &--hover {
        &:before {
            content: '';
            width: 100%;
        }
    }


    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0%;
        background-color: $primary;
        transition: width 350ms ease;
        transform-origin: left;
    }


    &--small {
        width: 80px;
        background-color: $primary;
    }


    &:after {
        content: '';
        height: inherit;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.card__container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.card__body,
.card__footer {
    padding: spacer(8);
}

.card__body {
    flex: 1;
}

.card__header {
    position: relative;
}

.card__title {
    font-size: $h3-font-size;
}

.card__image {
    height: 280px;
    width: 100%;
    object-fit: cover;
    max-width: unset;
}

.card__content {
    overflow-wrap: break-word;
    flex: 1;
}

.card__footer {
}

// Bade in cart
.card__badge {
    position: absolute;
    top: spacer(2);
    left: spacer(2);
    margin: 0;
    max-width: calc(100% - #{$spacer});
    overflow: hidden;
    text-overflow: ellipsis;
}

// Bordered card
.card--bordered {
    border: $card-border-width solid $card-border-color;

    .card-body,
    .card-footer {
        padding: $card-spacer-y;
    }
}

// Horizontal card
.card-horizontal-img {
    flex: 0 0 auto;
    width: auto;
}

// Responsive horizontal cards
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .card#{$infix}-horizontal {
            flex-direction: row;
        }
    }
}

.card--expertises {
    background: unset;
    box-shadow: unset;
    border: unset;
    overflow: hidden;
    position: relative;

    img, picture{
        height: 438px;
        border-radius: $border-radius;
        overflow: hidden;
        transition: transform 250ms ease-in-out;
    }

    &:hover {
        box-shadow: unset;
        transform: unset;

        img, picture {
            transform: translateY(-16px);
        }

        .card__icon {
            @extend .card__icon--hover;
        }

        .card__progress {
            @extend .card__progress--hover;
        }
    }

    .card__title {
        font-size: px-to-rem(24);
        line-height: 1.5;
        letter-spacing: 0.48px;
        font-weight: $font-weight-bold;
    }

    .card__content {
        font-size: px-to-rem(20);
        line-height: 140%;
        letter-spacing: 0.4px;
    }
}

.card--line-text {
    &:nth-child(3) {
        .card {
        position: relative;
        overflow: initial;

            .card__progress {
                &:after {
                    width: 50vw;
                    background-color: #e8e8e8;
                }
            }
        }
    }

    &:nth-child(3n+4) {
        .card {
        position: relative;
        overflow: initial;

        .card__progress-container {
            max-width: 90%;
        }

            .card__progress {
                &:after {
                    width: 50vw;
                    left: calc(-50vw - 4rem);
                    background-color: #e8e8e8;
                }
            }
        }
    }
}

.card--project {
    position: relative;
    background: transparent;
    border-radius: $border-radius-lg;
    box-shadow: unset;
    border: 0;
    height: 100%;
    transform: translateY(0);

    .btn {
        transition: transform 250ms ease-in-out;
        transform: translateX(0);
    }

    &:hover{
        .btn {
            transform: translateX(3px);
        }


    }

    .stretched-link--project {
        height: 100%;
        display: flex;
        align-items: flex-end;
    }

    .card__body {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: spacer(6) spacer(5);
        gap: spacer(3);

        .card__title {
            font-size: $h6-font-size;
            color: $white;

            @include media-breakpoint-up(lg) {
                width: 50%;
            }
        }
    }

    &-large {
        .card__body {
            padding: spacer(6);
            
            @include media-breakpoint-up(lg) {
                padding: spacer(10);
            }

            .card__title {
                font-size: px-to-rem(24);
                margin-bottom: 0;

                @include media-breakpoint-up(lg) {
                    font-size: px-to-rem(40);
                    font-weight: $font-weight-bold;
                    line-height: 44px; 
                    letter-spacing: 0.8px;
                }
            }
        }
    }

    .card__header {
        height: 100%;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(#191308, 0.26);
        }
    }
}


.card--vacancy {
    @extend .card--project;


    .stretched-link--vacancy {
        height: 100%;
        display: flex;
        align-items: flex-end;
    }

    .card__body {
        .card__title {
            margin-bottom: 0;
        }
    }
}

.card__tag {
    position: absolute;
    top: spacer(6);
    left: spacer(5);
    z-index: 1;
    color: $white;
    border: 1px solid $white;
    padding: spacer(4) spacer(5);
    border-radius: $border-radius-lg;
    font-size: px-to-rem(14);
    font-weight: $font-weight-medium;
}

.card--employee {
    box-shadow: none;
    border: 0;
    border-radius: $border-radius-lg;

    .card__header {
        background: $light;
    }
    
    .card__content {
        padding: spacer(8);
        text-align: center;

        .card__title {
            font-size: px-to-rem(24);
            font-weight: $font-weight-bold;
            font-family: $headings-font-family;
            margin-bottom: 0;
        }
    }

    &:hover {
        box-shadow: none;
        transform: none;
    }
}

.card--default {
    .card__header {
        .card__image {
            height: 200px;
        }
    }

    .card__body {
        padding: spacer(10);
        
        .card__title {
            font-size: px-to-rem(24);
            word-break: break-word;
            hyphens: auto;
        }
    }
    
    .card__footer {
        padding: 0 spacer(10) spacer(10) spacer(10);
    }

}

;@import "sass-embedded-legacy-load-done:247";