.cta-visual {

}

.cta-employee {
    position: relative;
    background-color: $white;
    border-radius: $border-radius-lg;
    padding: spacer(12) spacer(8) 0 spacer(8);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 0;
 
    @include media-breakpoint-up(md) {
        padding: spacer(7);
    }

    &.cover-next {
        @each $breakpoint, $pane-spacer in $pane-spacers {
            @include media-breakpoint-up($breakpoint) {
                margin-bottom: calc(-#{$pane-spacer} - spacer(10));
            }
        }
    }

    &__text {
        z-index: 1;
        grid-column: 1 / 13;
        color: $color-base;
     
        @include media-breakpoint-up(md) {
            grid-column: 1 / 7;
        }

        @include style-headings {
            color: $secondary;
        }

        .btn--container {
            position: absolute;
            right: 5px;
            bottom: 20px;
            left: 5px;
            justify-content: center !important;

            @include media-breakpoint-up(md) {
                margin-top: spacer(6);
                position: relative;
                right: unset;
                bottom: unset;
                left: unset;  
                justify-content: start !important;
            }
        }
    }

    &__image {
        grid-column: 1 / 13;

        img {
            width: 100%;
            border-radius: $border-radius-lg;
        }

        @include media-breakpoint-up(md) {
            grid-column: 7 / 13;
            
            img {
                position: absolute;
                right: 0;
                height: 100%;
                bottom: 0;
                width: auto;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:295";