$hamburger-layer-color: var(--pe-secondary);
$hamburger-layer-width: 20px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 3px;
$hamburger-padding-x: spacer(7);
$hamburger-padding-y: spacer(3);
$hamburger-hover-opacity: 1;

.hamburger {
    display: flex;
    color: $secondary !important;
    gap: spacer(2);
    font-weight: $font-weight-medium;

    @extend .btn;
    @extend .btn--rounded;
    @extend .btn--senary-inverted;

    &:hover {
        background-color: rgba($tertiary, 20%);
    }
}

// .is-dark .hamburger {
//     color: $white !important;

//     --pe-secondary: #{$white};
// }

.hamburger.is-active {
    .hamburger {
        @extend .is-active;
    }
}

;@import "sass-embedded-legacy-load-done:224";