$pe-popup-vars: (
    "pe-popup-background-color": #44AC80,
    "pe-popup-title-fs": px-to-rem(16),
    "btn-background": white,
    "pe-popup-body-padding": spacer(2) 0 spacer(4) 0,
);

.popup__title, .popup__body {
    color: $white;
}

.popup__body {
    font-size: px-to-rem(24);
    font-weight: $font-weight-bold;
    line-height: 1.2;
}

.pe-popup .btn {
    width: fit-content;
    display: flex;
    justify-content: center;
    font-weight: 600;
    gap: spacer(2);

    .icon {
        transform: rotate(-90deg);
    }
}

@include set-popup-variables($pe-popup-vars);

;@import "sass-embedded-legacy-load-done:302";