.search {
    position: relative;
}

.searchbar__container {
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.searchbar__icon {
    @extend .btn;

    display: flex;
    align-items: center;
    justify-content: center;
    background: unset;
    background: $primary;
    margin-left: -#{spacer(13)};
    border-radius: 0 $border-radius-md $border-radius-md 0;
}

.search-link {
    @extend .btn;
    @extend .btn--rounded;
    @extend .btn--quinary-inverted;

    &:hover {
        background-color: rgba($tertiary, 20%);
    }

    padding: spacer(3);
    margin-left: auto;
    flex-shrink: 0;
}

.searchbar__field {
    padding: spacer(3);
    border-radius: $border-radius-md;
    padding-right: spacer(13) !important;
    min-width: 140px;
    width: calc(100% / 1.5);

    @include media-breakpoint-up(lg) {
        outline: none;
        border: 1px solid $secondary;
        transition: all $global-duration;

        &:focus {
            border: 2px solid $secondary;
        }
    }
}

;@import "sass-embedded-legacy-load-done:235";