$header-offset: 70px;

[data-parent-depth="1"]{
    background-color: #1a1924;
    position: relative;

    @include media-breakpoint-up(lg){
        padding-top: calc(var(--header-height) + $header-offset);
    }
}

[data-parent-depth="2"] {
    background-color: #262534;

    @include media-breakpoint-up(lg){
        padding-top: calc(var(--header-height) + $header-offset);
    }

    .menu__item {
        @include media-breakpoint-up(lg) {
            font-size: px-to-rem(20);
        }
    }
}

[data-parent-depth="3"] {
    @include media-breakpoint-up(lg){
        padding-top: calc(var(--header-height) + $header-offset);
    }
}


.mega-menu-submenu {
    margin: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: spacer(8) 0;
    opacity: 0;
    pointer-events: none;
    padding: 0 spacer(8);
    justify-content: space-between;
    right: 0;
    left: 0;
    min-height: fit-content;
    flex: 1;

    .menu__item-overflow {
        width: 100%;
    }
    
    .menu__link {
        width: 100%;
        display: flex;
        text-wrap: balance;
        align-items: center;
        gap: spacer(1);
    }

    .menu__link-subtitle {
        font-size: px-to-rem(14);
    }

    &.is-active {
        z-index: 10;
        pointer-events: initial;
        opacity: 1;
    }

    &:hover {
        .menu__link {
            opacity: 0.5;
        }
    }

    .menu__item {
        color: $white;

        &:hover {
            .menu__link {
                opacity: 1;
            }

            .menu__item-counter {
                opacity: 1;
            }
        }
    }
}


.megamenu-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: spacer(4);
    justify-content: flex-start;

    @include media-breakpoint-up(md) {
        &--custom-height {
            height: fit-content;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 180px;
    }

    &__inner {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        position: relative;
        height: 100%;
        transition: height 400ms ease-out;
    }

    &--bg {
        justify-content: flex-start;

        &:after {
            content: "";
            width: 50vw;
            background-color: rgba($white, 0.08);
            min-height: 100%;
            display: block;
            position: absolute;
            top: 0;
            pointer-events: none;
        }
    }
}


.main-header__desktop {
    .menu--secondary, .menu--tertiary {
        @include media-breakpoint-up(lg){
            padding-left: spacer(16);
        }
    }
}
;@import "sass-embedded-legacy-load-done:277";