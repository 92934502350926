.menu--secondary {
  position: relative;
  font-size: px-to-rem(22);
  flex-direction: column;
  gap: 1em;
  height: auto;

  &:hover {
    .menu__link {
        opacity: 0.5;
    }
}

  .menu__item {
      height: initial;
      padding: unset;
      position: unset;
      color: $white;
      gap: spacer(4);

      &:hover {
          .menu__link {
              opacity: 1;
          }

          .menu__item-counter {
              opacity: 1;
          }
      }
  }

  .submenu {
      background-color: unset;
  }

  .icon {
    color: $white;
  }

}

;@import "sass-embedded-legacy-load-done:269";