.menu--mobile {
    .menu__item {
        color: $white;
        font-size: px-to-rem(20);
    }

    .menu__item--level-2 {
        font-weight: $font-weight-bold;
        font-size: px-to-rem(20);
        border: unset;
    }
}
;@import "sass-embedded-legacy-load-done:281";