$tab-content-gap: spacer(20);

.tabs {
    // @extend .list-unstyled;

    display: flex;
    align-items: center;
    margin-bottom: spacer(8);

    @include media-breakpoint-up(lg) {
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        margin-left: 0;
        margin-bottom: 0;
    }
}

.tab {
    position: relative;
    padding: spacer(4);
    overflow: hidden;
    border: 1px solid transparent;
    transition: border-top 0.3s, border-left 0.3s, border-right 0.3s;
    color: var(--pe-headings-color);

    // cursor: pointer;

    @include media-breakpoint-up(lg) {
        transition: border-top 0.3s, border-left 0.3s, border-bottom 0.3s;
    }

    &.is-active {
        border-color: rgba($black, 0.1);
        border-bottom-color: $white;
        margin-bottom: -2px;
        z-index: 9;

        @include media-breakpoint-up(lg) {
            border-color: rgba($black, 0.1);
            border-right-color: $white;
            margin-right: -1px;
            margin-bottom: 0;
        }
    }
}

.tabs--projects {
    padding-left: 0;

    .tab {
        padding: spacer(2) spacer(4);
        border: 0;
        border-left: 2px solid rgba($black, .06);

        &.is-active {
            border-left: 2px solid $primary;
        }
    }
}

@each $name, $color in $theme-colors {
    .bg--#{$name} {
        .tab.is-active {
            border-bottom-color: $color;
            margin-bottom: -1px;

            @include media-breakpoint-up(lg) {
                border-right-color: $color;
                margin-right: -1px;
            }
        }
    }
}

.tabs__container {
    @include media-breakpoint-up(lg) {
        margin-top: 0;
        display: flex;
        flex-flow: column nowrap;
        gap: $tab-content-gap;
        transition: height $global-duration;
        padding-right: 0 !important;
        position: sticky;

        @include media-breakpoint-up(lg) {
            height: var(--height);
        }
        
        @each $breakpoint, $pane-spacer in $pane-spacers {
            @include media-breakpoint-up($breakpoint) {
                top: calc(#{$pane-spacer} * 2);
            }
        }

        &::-webkit-scrollbar {
            width: 0;
        }
    }
}

.tab__image {
    height: 100%;
    border-radius: $border-radius-md;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    display: flex;
    align-items: flex-end;

    @include media-breakpoint-up(lg) {
        aspect-ratio: 14 / 16;
    }

    .img-fluid {
        height: 140%;
        object-fit: cover;
        width: 100%;
    }
}

.tabs--image-wrapper {
    // position: absolute;
    left: calc(var(--pe-gutter-x) * 0.5);
    right: calc(var(--pe-gutter-x) * 0.5);
}

.tab__content {
    transition: opacity #{$global-duration * 4}, transform #{$global-duration * 4} ease-in-out;
    display: flex;
    gap: spacer(7);
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
        position: absolute;
        opacity: 0;
        bottom: auto;
        transform: translateY(-20%);
        flex-shrink: 0;
        gap: spacer(5);
        scroll-snap-align: start;
        pointer-events: none;

        & > * {
            flex: 1;
            flex-basis: calc(50% - #{spacer(4)});

            &:nth-child(3n + 3) {
                flex-grow: 0;
            }
        }
    }

    &.is-active {
        transition: opacity #{$global-duration * 4} 0.5s, transform #{$global-duration * 4} 0.5s ease-in-out;
        height: auto;
        opacity: 1;
        transform: translateY(0%);
        pointer-events: all;

        & ~ .tab__content{ 
            transform: translateY(20%);
        }
    }
}

.row--tab-content-wrapper {
    height: 100vh;
    display: flex;
    align-items: center;

    @each $breakpoint, $pane-spacer in $pane-spacers {
        @include media-breakpoint-up($breakpoint) {
            margin-top: -#{$pane-spacer};
        }
    }
}

#pinned-content {
    width: 100%;
}

;@import "sass-embedded-legacy-load-done:288";