@use "sass:map";
@use "sass:color";

/* stylelint-disable */

$darken-amount: 10%;
$button-transition-duration: $global-duration * 1.6;

.btn {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    background: initial;
    line-height: 1.3;
    padding: spacer(4) spacer(5);
    color: initial;
    text-decoration: none;
    width: auto;
    gap: spacer(4); // min amount of space between text and icon
    z-index: 1;
    border: none;

    @extend .btn--rounded;

    .icon {
        transition: transform $global-duration;
        top: 1px;
        position: relative;
    }

    &:hover {
        text-decoration: none;

        .icon {
            transform: translateX(3px);

        }

        &[target="_blank"] {
            .icon {
                transform: translateX(10px) translateY(10px);
            }
        }
    }
}

// Button colors
@each $name, $map in $btn-colors {
    .btn--#{$name} {
        background-color: map.get($map, "background");
        transition: background-color $button-transition-duration,
            color $button-transition-duration;
        color: map.get($map, "color");

        &:hover {
            background-color: map.get($map, "hoverColor");
            color: map.get($map, "color");

            @if $name == "primary" {
                color: map.get($map, "background");
            }
        }

        // Button colors inverted
        &-inverted {
            color: map.get($map, "background");
            background: transparent;
            box-shadow: inset 0 0 0 1px map.get($map, "background");
            transition: background-color $button-transition-duration,
                color $button-transition-duration;

            @if $name == "primary" {
                color: map.get($map, "color");
            }

            &:hover {
                background-color: map.get($map, "background");
                color: map.get($map, "color");
            }
        }
    }
}

.btn--ghost {
    border: 1px solid $white;
    color: $white;
    background: transparent;

    &:hover {
        background-color: $white;
        color: $color-base;
        transition: background-color $button-transition-duration;
    }
}

.btn--circle {
    aspect-ratio: 1 / 1;
    padding: spacer(3);

    &:hover {
        .icon {
            transform: unset;
        }
    }
}

.btn--video {
    background-color: $primary;
    color: $white;
    padding: spacer(5);
    height: 72px;
    max-width: 72px;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        left: 3px;
    }

    &:hover {
        background-color: color.adjust($primary, $lightness: -10%);
    }
}

.btn-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: spacer(4);
    justify-content: flex-start;
}

// Used to show icon first, button text second
.btn--reversed {
    flex-direction: row-reverse;
}

.btn--rounded {
    border-radius: 50rem;
}

/* Add this class to any button with an icon inside of it
this will take care of the icon padding when used before or after text
*/

// .btn-return {
//     padding: spacer(2) spacer(4);

//     & > .icon {
//         pointer-events: none;
//     }
// }

.btn--center {
    width: fit-content;
    margin: 0 auto;
}

.btn--container {
    gap: spacer(2);
}

.btn--disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: default;
}

[data-background] .btn--ghost {
    border-color: var(--pe-heading-color);
    color: var(--pe-heading-color);

    &:hover {
        color: $secondary;
    }
}


.btn--card-inverted {
    background-color: transparent;
    color: $white;
    box-shadow: 0 0 0 2px $white;

    &:hover {
        background-color: $color-base;
        color: $white;
    }
}

;@import "sass-embedded-legacy-load-done:222";