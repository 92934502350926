.video__text {
    position: relative;
    padding: spacer(5);
    background: $white;
    color: $tertiary;
    font-size: $h6-font-size;
    line-height: $line-height-sm;
    text-align: center;
    border-radius: $border-radius-lg;
    margin-bottom: spacer(6);

    &:before {
        content: "";
        width: 3px;
        height: 40px;
        background-color: $primary;
        position: relative;
        display: block;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        margin-top: spacer(8);

        @include media-breakpoint-up(md){
            height: 40px;
            transform: translateY(-25%);
        }

        @include media-breakpoint-up(xl){
            height: 100px;
            transform: translateY(-25%);
        }

        @include media-breakpoint-up(xxl){
            transform: translateY(-40%);
        }
    }

    @include media-breakpoint-up(lg) {
        position: absolute;
        background: transparent;
        left: 50%;
        transform: translateX(-50%);
        max-width: 50%;
        bottom: spacer(4);
        color: $white;
        transition: opacity .2s ease-in-out;
        margin-bottom: 0;
    }
}

.video__container {
    position: relative;
    border-radius: $border-radius-lg;
    display: flex;
    z-index: 1;

    video {
        border-radius: $border-radius-lg;
        z-index: -1;
    }
    
    &:before {
        @include media-breakpoint-up(lg) {
            position: absolute;
            width: 100%;
            height: 100%;
            content: "";
            background: linear-gradient(180deg, rgba(39, 38, 53, 0%) 0%, rgba(39, 38, 53, 38%) 41%, rgba(39, 38, 53, 92.5%) 65.5%, #272635 100%);
            border-radius: $border-radius-lg;
            opacity: .57;
            transition: opacity .2s ease-in-out;
            pointer-events: all;
        }
    }

    .btn--video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity .2s ease-in-out;
        cursor: pointer;

        &:hover {
            .icon {
                transform: none;
            }
        }
    }

    &.play {
        &:before {
            opacity: 0;
            pointer-events: none;
        }

        .btn--video {
            opacity: 0;
        }

        .video__text {
            opacity: 0;
        }
    }
} 


;@import "sass-embedded-legacy-load-done:233";