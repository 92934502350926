.form-control {
    border-radius: $border-radius-md;
    font-family: $headings-font-family;
    background-color: $white;
}

input, textarea, select {
    @extend .form-control;

    &[type="radio"], &[type="checkbox"] {
        &:checked {
            background-image: none;
        }
    }

}

select {
    background-position: right spacer(3) center;
}

.gfield_radio, .gfield_checkbox {
    display: flex;
    flex-direction: column;
    gap: spacer(2);
}

.form-container {
    border-radius: $border-radius-2xl;
    background: $white;
    padding: spacer(10);
    
    @include media-breakpoint-up(lg) {
        padding: spacer(20) spacer(30);
    }
}

.text-container {
    padding: spacer(10) 0;
    
    @include media-breakpoint-up(lg) {
        padding: spacer(20) 0;
    }
}

;@import "sass-embedded-legacy-load-done:308";