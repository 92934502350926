.usp {
    display: flex;
    gap: spacer(4);
    background: $white;
    border-radius: px-to-rem(16);
    padding: spacer(6);
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(md){
        flex-direction: column;
        padding: spacer(7) spacer(7) spacer(6) spacer(7);
        gap: spacer(7);
    }
}

.ups__title {
    font-size: px-to-rem(18);
    line-height: 1.33;
    font-weight: $font-weight-semibold;
    margin: 0;
}

.usp__icon {
    color: $primary;
    font-size: px-to-rem(30);
}

.usp__content {
    width: fit-content;
    align-items: center;

    & > * {
        margin-bottom: 0;
    }
}

.ups__text {
}

;@import "sass-embedded-legacy-load-done:301";