.pane {
    display: block;

    @each $breakpoint, $pane-spacer in $pane-spacers {
        @include media-breakpoint-up($breakpoint) {
            padding: $pane-spacer 0;
        }
    }

    &--tabs_projects {
        // .pin-spacer {
        //     height: 100% !important;
        //     padding-bottom: 0 !important;
        // }

        .tabs--image-wrapper {
            align-self: start;
            display: flex;
            flex-direction: column;
            gap: spacer(10);
        }

        .title {
            font-size: px-to-rem(18);
            font-style: normal;
            font-weight: $font-weight-medium;
            line-height: 2; /* 200% */
            letter-spacing: 0.36px;
            color: var(--pe-heading-color);

            @include media-breakpoint-up(lg) {
                margin-bottom: spacer(5);
            }
        }
    }
}

.pane--cta_visual {
    $pane-spacers: (
        xs: #{$pane-spacer * 1.725},
        md: #{$pane-spacer * 2},
        lg: #{$pane-spacer * 4.3},
    );

    @each $breakpoint, $pane-spacer in $pane-spacers {
        @include media-breakpoint-up($breakpoint) {
            padding: $pane-spacer 0;
        }
    }
}

.pane--swiper {
    overflow: hidden;
}

.pane--team {
    @include media-breakpoint-up(lg) {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            background-image: url('../../img/team-bg.svg');
            background-repeat: no-repeat;
            background-size: auto;
            background-position: top left;
            width: 100%;
            height: 100%;
        }
    }
}

.hero--default {
    + .breadcrumbs-container {
        margin-top: calc(0px - var(--header-height, 0px) + (var(--header-height, 0px)));

        @include media-breakpoint-up(lg){
            margin-top: calc(0px - var(--header-height, 0px) + (var(--header-height, 0px) / 2));
        }
        
    }
}

.pane--video {
    & + .pane {
        @include media-breakpoint-down(lg){
            padding-top: 0;
        }
    }
}

.pane--hero {
    position: relative;

    &:after {
        content: '';
        background-color: $white;
        position: absolute;
        border-radius: $border-radius;
        inset: 0;
        z-index: -1;

        @extend .container;
    }

    @each $breakpoint, $pane-spacer in $pane-spacers {
        @include media-breakpoint-up($breakpoint) {
            margin: calc($pane-spacer + var(--header-height)) 0 $pane-spacer 0;
        }
    }

    + .pane--archive {
        padding-top: 0;
    }

    + .breadcrumbs-container {
        @each $breakpoint, $pane-spacer in $pane-spacers {
            @include media-breakpoint-up($breakpoint) {
                margin-top: - $pane-spacer;
            }
        }
    }

    .archive-title {
        text-align: center;
        margin-bottom: 0;

        &:before {
                content: "";
                height: 3px;
                width: 100px;
                background-color: $primary;
                position: relative;
                display: block;
                top: -#{spacer(6)};
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
        }
    }
}

;@import "sass-embedded-legacy-load-done:218";