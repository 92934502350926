// Hero swiper

.swiper--hero {
    height: 70vh;

    .swiper-wrapper {
        height: 100% !important;
    }

    .swiper__slide {
        height: 100%;

        .img-fluid {
            width: 100%;
            object-fit: cover;
            object-position: center;
            height: 100%;
        }
    }
}
;@import "sass-embedded-legacy-load-done:257";