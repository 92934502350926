.pagination-block {
    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0;
        text-decoration: none;
        list-style-type: none;
        color: $color-base;
        font-weight: $font-weight-bold;
        margin-bottom: 0;
        gap: spacer(6);

        .prev,
        .next {
            align-items: center;
            display: flex;
            border: 1px solid $color-base;
            border-radius: $border-radius-pill;
            padding: spacer(6);
            color: $secondary;
         
            .material-icons {
                font-size: px-to-rem(12);
            }
        }

        a {
            color: $color-base;
            text-decoration: none;

            &:hover {
                color: $primary;
            }
        }

        li {
            &:before {
                display: none;
            }

            &.current {
                background-color: $primary;
                color: $white;
                padding: 0 spacer(3);
                border-radius: $border-radius-pill;
                aspect-ratio: 1 / 1;
                display: flex;
                justify-content: center;
                align-items: center;
            }


            &.btn {
                color: $secondary;

                a {
                    color: $secondary;
                    text-decoration: none;
                }

                &.disabled {
                    opacity: .6;

                    &:before {
                        display: none;
                    }

                    &:hover {
                        transition: none;
                    }
                }
            }
        }

        .first {
            display: flex;
            flex-direction: row-reverse;

            .material-icons {
                transform: scaleX(-1);
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:293";