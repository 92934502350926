@use "sass:map";

:root {
    @each $name, $map in $bg-colors {
        --bg--#{$name}: #{map.get($map, "background")};
        --bg--#{$name}-color: #{map.get($map, "color")};
    }

    --header-height: #{$header-height};
}

;@import "sass-embedded-legacy-load-done:209";