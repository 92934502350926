.card-large {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  border-radius: px-to-rem(12);
  gap: spacer(8);
  box-shadow: 0 0 8px rgba(0, 0, 0, 3%);
  padding: spacer(12);
 
  @include media-breakpoint-up(lg) {
    gap: spacer(16);
    padding: spacer(20);
  }

  .card-large__title {
    font-size: px-to-rem(28);
    font-weight:  $font-weight-bold;
    line-height: 1.33;
    margin-bottom: spacer(8);
  }

  .card-large__col {
    flex: 1;
  }

  @include media-breakpoint-up(lg){
    flex-direction: row;
  }
}

.steps {
  min-height: 100vh;

  .card__container {
    gap: 2rem;
  }
}

;@import "sass-embedded-legacy-load-done:253";