.breadcrumbs {
    padding: spacer(4) 0;
    font-size: px-to-rem(13);
    line-height: 160%;
    letter-spacing: 0.65px;
 
}

.breadcrumbs__nav {
    display: flex;
    gap: spacer(2);
    align-items: center;
    padding-left: 0;
    margin: 0;
}

.breadcrumbs__seperator {
    font-size: px-to-rem(14);
    display: flex;
    align-items: center;

    & > * {
        font-size: inherit;
    }
}

.breadcrumbs__link {
    font-size: inherit;
    color: $tertiary;
    text-decoration: none;
    line-height: 1;
    transition: color 250ms ease-in;

    &:hover {
        color: $primary;
    }
}

.breadcrumb_last {
    opacity: 0.75;
    font-size: inherit;
    line-height: 1;
}

;@import "sass-embedded-legacy-load-done:264";