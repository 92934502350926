.mega-menu {
    position: absolute;
    padding: spacer(8);
    top: 100%;
    background-color: $white;
    z-index: $zindex-dropdown;
    margin: 0 auto;

    @extend .container;

    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    min-height: 100%;
    pointer-events: none;
    transform: translateY(20px);
    transition: opacity $global-duration ease-in, transform $global-duration ease-in, visibility $global-duration ease-in;
    display: none;

    @include media-breakpoint-up(lg){
        display: initial;
    }
}

.menu__item--mega-menu {
    position: unset;

    &:hover {
        // Show direct mega menu on hover
        & > .mega-menu {
            opacity: 1;
            visibility: visible;
            pointer-events: initial;
            transform: translateY(0);
        }

        // Show direct menu overlay on hover
        & > .menu-overlay {
            opacity: 1;
        }
    }

    &.menu__item--level-2 {
        font-weight: $font-weight-bold;
    }
}

// Menu cols options for mega menu
@for $i from 1 through 10 {
    .menu__col--#{$i} { 
        .menu--submenu {
            @include media-breakpoint-up(lg){
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
            }

            // Set basis on childs of submenu
            & > * {
                @include media-breakpoint-up(lg){
                    flex-basis: calc(100% / #{$i});
                }
            }
        }

        .card--row {
            flex-basis: 100%;
        }        
    }
}

;@import "sass-embedded-legacy-load-done:275";