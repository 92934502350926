.double_image_grid {
    display: grid;
    grid-gap: px-to-rem(28);
    grid-auto-flow: column;

    @include media-breakpoint-up(md) {
        grid-auto-flow: initial;

        & > * {
            &:nth-child(2) {
                margin: 0 spacer(6);
                max-width: 200px;
            }
        }
    }
}

.image-gallery {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: px-to-rem(28);
    width: fit-content;

    // @include media-breakpoint-up(md) {
    //     grid-template-columns: repeat(2, 1fr);
    //     grid-auto-rows: auto;
    // }

    @include media-breakpoint-up(md) {
        grid-template-columns: auto auto fit-content(325px);
        padding: 0 spacer(6);
        grid-auto-rows: unset;
    }

    img,
    picture {
        object-fit: cover;
        max-width: 100%;
        width: 100%;
        aspect-ratio: 1/1;
    }

    .image__container {
        --clip-image-wrapper: 0 0, 100% 0, 100% 0, 0 0;
        
        clip-path: polygon(var(--clip-image-wrapper));
        width: 100%;
        display: block;
        height: 100%;
    }

    .image {
        // display: flex;
        border-radius: $border-radius-lg;
        overflow: hidden;
        height: fit-content;
    }

    & > * {
        @include media-breakpoint-up(md) {
            &:nth-child(2) {
                margin-top: spacer(18);
            }

            &:nth-child(3) {
                margin-top: spacer(8);
            }
        }
    }

    // & > * {
    //     grid-column: 1/7;

    //     &:nth-child(1) {
    //         grid-column: 1/8;
    //     }

    //     &:nth-child(2) {
    //         grid-column: 8/16;
    //     }

    //     &:nth-child(3) {
    //         grid-column: 16/24;
    //     }
    // }
}

;@import "sass-embedded-legacy-load-done:299";