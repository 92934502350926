.tcmp {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, max-content) 38%;
    justify-content: space-around;
    align-items: center;
    gap: var(--spacer-m) var(--spacer-xl-2xl);
}

.tcmp__image {
    width: 100%;

    img,
    picture {
        display: block;
        border-radius: $border-radius-lg;
    }
}

;@import "sass-embedded-legacy-load-done:297";