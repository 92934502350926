input[type="radio"] {
    @extend .form-check-input;

    width: spacer(4) !important;
    height: spacer(4) !important;
    padding: 0 !important;
    margin: 0;

    &:checked {
        background-color: $primary !important;
        border-color: $primary !important;
        background-image: none;
    }
}

;@import "sass-embedded-legacy-load-done:317";