.tips {
    transition: transform 0.25s ease-in-out;

    &:hover {
        transform: rotate(45deg);
    }
}

/* stylelint-disable */
#powerTip {
    background-color: $white;
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow-sm;
    color: $secondary;

    .box_view_html {
        color: $secondary;
    }

    &:before {
        display: none;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.ihotspot_pulse {
    -webkit-animation: Ani 5s infinite !important;
    animation: Ani 5s infinite !important;   
}

@keyframes Ani {
    0% {
        box-shadow: 0 0 0 rgba(255, 255, 255, 0%);
        border: 1px solid rgba(255, 255, 255, 0%);
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    35% {
        box-shadow: 0 0 50px #fff;
        border: 1px solid #fff;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    
    70% {
        box-shadow: 0 0 60px rgba(255, 255, 255, 0%);
        border: 0 solid rgba(255, 255, 255, 0%);
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
    
    100% {
        box-shadow: 0 0 60px rgba(255, 255, 255, 0%);
        border: 0 solid rgba(255, 255, 255, 0%);
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
}
/* stylelint-enable */

.hotspot__container {
    .hotspot__info {
        display: none;
        position: relative;
        background-color: $white;
        border-radius: $border-radius-lg;
        padding: spacer(5);
        color: $secondary;
        z-index: 1;

        @include media-breakpoint-up(lg) {
            display: block;
            transform: translateY(50%);
            margin-top: -25%;
            margin-left: calc($grid-gutter-width * -0.5);
            margin-right: $grid-gutter-width;
        }

        .hotspot__icon {
            color: $primary;
            margin-bottom: spacer(5);
        }
    }

    .hotspot__text {
        position: relative;
        margin-bottom: spacer(4);

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }

    .hotspot__image {
        position: relative;

        &:after {
            @include media-breakpoint-up(lg) {
                content: "";
                width: 3px;
                height: 100px;
                background-color: $primary;
                position: absolute;
                display: block;
                top: 0;
                left: 50%;
                transform: translate(50%, -50%);
                z-index: 1;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:240";